<template>
  <v-app>
    <div class="area">
      <v-row justify="center">
        <v-container class="head">
          <v-avatar size="150" tile>
            <v-container>
              <v-img :src="$config.logo" />
            </v-container>
          </v-avatar>
          <div class="text-center">
            <h2 class="mt-2" style="color: #122940">
              {{ $config.title }}
            </h2>
            <h2 class="mt-2" style="color: #122940">
              منظومة سير العمل الإداري
            </h2>
            <h3 class="mt-2" style="color: #0080ff">|| مسعى ||</h3>
          </div>
        </v-container>
      </v-row>
      <div class="checked">
        <div class="card" @click="toSorE()">
          <div
            class="text-center d-flex justify-center flex-column align-center"
          >
            <v-img
              width="200"
              src="@/assets/images/check.png"
              class="mb-4"
            ></v-img>
            <h1>تم تسليم المعاملة</h1>
          </div>
        </div>
      </div>
      <v-footer>
        <v-row justify="center">
          <div class="d-flex justify-center">
            <span style="color: #7c7c7c">
              {{ $t("tm-alttwyr-bwasth") }}
              <a href="https://www.google.com/">
                {{ $config.company }}
              </a>
              |
              {{ new Date().getFullYear() }}
            </span>
          </div>
        </v-row>
      </v-footer>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      process: {},
    };
  },
  created() {
    this.process = process.env;
  },

  methods: {
    toTreatment() {
      this.$router.push({
        path: `/treatment`,
      });
    },
    toLogin() {
      this.$router.push({
        path: `/login`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
}
.area {
  background: #ffffff;
  background-size: 50% auto;
  background: white;
  width: 100%;
  height: 100%;
}
.checked {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  height: 74%;
  gap: 2rem;
  margin: 2rem;
  width: 100%;
}
</style>
